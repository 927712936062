import Pill from './flip-ui-kit/Pill';
import { DocIcon, BoltIcon, ChunksIcon, ShieldTickIcon } from '../icons/small';

type PillProps = {
  maxWidth?: boolean;
};

export const OnChainPill = (props: PillProps) => (
  <Pill key="onchain" text="On-chain" color="neutral" iconColor="text-cf-light-3" {...props} />
);

export const CcmPill = (props: PillProps) => (
  <Pill color="neutral" text="CCM" Icon={DocIcon} {...props} />
);

export const BoostPill = ({ text, ...props }: PillProps & { text: string }) => (
  <Pill color="neutral" Icon={BoltIcon} iconColor="text-cf-pink-1" text={text} {...props} />
);

export const DcaPill = ({ chunks, ...props }: { chunks: string | number } & PillProps) => (
  <Pill
    color="neutral"
    text={`${chunks} chunks`}
    Icon={ChunksIcon}
    iconColor="text-cf-light-3"
    {...props}
  />
);

export const FokPill = (props: PillProps) => (
  <Pill
    color="neutral"
    text="Slippage Protection"
    Icon={ShieldTickIcon}
    iconColor="text-cf-light-3"
    {...props}
  />
);
